/* eslint-disable */
<template>
    <div id="active" class="wrapper">
        <div class="left">
            <!-- <p>
                <span @click="genArrs()">SET</span> || 
                <span @click="toggleTimer()">TIMER</span> ({{ curSec }}) || 
                <span @click="transition = !transition">Transition [{{ transition }}]</span> ||
                <span @click="dampen = !dampen">Dampen [{{ dampen }}]</span>
            </p>
            
            <p>{{ asShow }} -- {{ curSec + dampenTime }}</p>
            <p>{{ arrays.type1.arr }}</p> -->
            
            <h1>Active Monitor State</h1>

            <div class="break"></div>

            <div class="dim-wrapper">
              <div class="dim-label">
                Transition
                <!-- <svg class="greyon"  width="20px" :viewBox="paths.brightness.vb"  xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
                  <path :d="paths.brightness.d"/>
                </svg> -->
              </div>
              <div class="dim-slide">
                <input v-model="transTime" type="range" min="0" max="1"  step=".1" class="slider" id="myRange" width="100%">
              </div>
              <div class="dim-value">
                {{ transTime }}
              </div>
            </div>

            <div class="dim-wrapper">
              <div class="dim-label">
                Dampen
                <!-- <svg class="greyon"  width="20px" :viewBox="paths.brightness.vb"  xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
                  <path :d="paths.brightness.d"/>
                </svg> -->
              </div>
              <div class="dim-slide">
                <input v-model="dampenTime" type="range" min="1" max="10"  step="1" class="slider" id="myRange" width="100%">
              </div>
              <div class="dim-value">
                {{ dampenTime }}
              </div>
            </div>

            <div class="option-wrapper">
                <div class="option-check" :class="{'isActive':timer != null}"  @click="toggleTimer()"></div>
                <div class="option-label dim">Timer</div>
            </div>

            <div class="break"></div>

            <h2>Thermal Sentry</h2>
            <div class="tik-track">
                <active-demo-tic v-for="(tik, index) in arrays.ts.arr" :key="index"
                                :aval="tik"                
                                :aid="index" 
                                :cursec="curSec" @toggle-state="setActive('ts', index)"></active-demo-tic>
            </div>

            <h2>Intelligent Sentry</h2>
            <div class="tik-track">
                <active-demo-tic v-for="(tik, index) in arrays.as.arr" :key="index"
                                :aval="tik"                
                                :aid="index" 
                                :cursec="curSec" @toggle-state="setActive('as', index)"></active-demo-tic>
            </div>
           
        </div>
        <div class="right">
            <div class="sentry as" :class="{'showME':asState, 'smoothME':transition}" :style="{'transition': 'opacity ' + transTime +'s'}"></div>
            <div class="sentry ts" :class="{'showME':tsState, 'smoothME':transition}"  :style="{'transition': 'opacity ' + transTime +'s'}"></div>
        </div>
    </div>

  </template>
  
  <script>
// import axios from 'axios';
// import Display from './Display.vue';
// import HelperIcons from '@/components/HelperIcons.vue';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import ActiveDemoTic from './ActiveDemoTic.vue';


export default {
  name: 'ActiveDemo',
  components: {
    ActiveDemoTic
    // Display
    // HelperIcons
    // SvgHelper
    // HelloWorld
  },
  data(){
    return{
      asShow:null,
      tsShow: null,
      timeHold:4, 
      dampen: false,
      transition:false,
      transTime:0,
      dampenTime:1,
      timer: null,
      curSec:0,
      curView: 'view1',
      arrLength: 120,
      arrays: {
        type1: {label:'foo1', arr:[]},
        as: {label:'Intelligent Sentry', 
            arr:[ false, false, true, false, true, false, true, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, true, true, false, false, false, false, false, false, false, false, false, true, false, false, true, false, false, false, false, false, true, false, false, true, false, true, false, true, true, false, false, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, false, true, false, false, true, false, false, true, false, false, true, false, true, false, false, false, false, false, true, false, false, false, false, false, true, true, false, false, false, false, true, false, false, true, false, false, false, false, false, true, true, true, true, false, false, false, false, false, false, false ]
        },
        ts: {label:'Thermal Sentry',
            arr:[ false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, false, false, false, true, true, false, true, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false ]
        }
      }
      
    }
  },
  mounted() {
    // this.arr1.length = 100;
    // window.addEventListener('resize', this.bw);
    // this.bw();
    // this.remoteCall();
  },
  unmounted() {
    // window.removeEventListener('resize', this.bw);
    if (this.timer != null){
        clearInterval(this.timer);
    }
  },
  computed:{
    asState: function(){
        var state = false;
        // if (this.dampen){
        //         if (this.asShow != null && this.curSec < this.asShow){
        //         state=true;
        //     }
        // } else {
        //     if (this.arrays.as.arr[this.curSec]==1){
        //         state=true;
        //     }
        // }
        if (this.asShow != null && this.curSec < this.asShow){
               state=true;
            }
        return state;
    },
    tsState: function(){
        var state = false;
        // if (this.dampen){
        //         if (this.tsShow != null && this.curSec < this.tsShow){
        //         state=true;
        //     }
        // } else {
        //     if (this.arrays.ts.arr[this.curSec]==1){
        //         state=true;
        //     }
        // }
        if (this.tsShow != null && this.curSec < this.tsShow){
                state=true;
            }
        return state;
    }
    
  },
  methods: {
    toggleTimer: function(){
        if (this.timer != null){
            clearInterval(this.timer);
            this.timer = null;
            this.curSec = 0;
            this.asShow = null;
            this.tsShow = null;
            // this.genArrs();
        } else {
            this.timer = setInterval(this.myTimer, 250);
        }
       
    },
    myTimer: function(){
        console.log('t');
        if(this.curSec===this.arrLength){
            this.curSec=0;
            this.asShow = null;
            this.tsShow = null;
        } 
        this.curSec+=1;

        // AS CHECK 
        if (this.arrays.as.arr[this.curSec]){
            this.asShow = (this.curSec + Number(this.dampenTime));
        }
        // TS CHECK
        if (this.arrays.ts.arr[this.curSec]){
            this.tsShow = (this.curSec + Number(this.dampenTime));
        }
        
    },
    setActive: function(arr, i){
        var bool = this.arrays[arr].arr[i];
        bool = !bool;
        // console.log(bool);
        this.arrays[arr].arr[i] = bool;
    },
     genArrs: function(){
        // console.log('foo');
            // for (var arr in this.arrays){
            //     console.log(arr);
            //     console.log(this.arrays[arr].label);
            //     for (var i = 0; i < this.arrLength; i++) {
                    
            //         this.arrays[arr].arr[i] = false;
            //         if(i==10){
            //             this.arrays[arr].arr[i] = true;
            //         }
            //     }
            // }
     }
  }
}
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss">
    @import "styles/active.scss";
    
  </style>
  