<template>
  <div>
    <router-view/>
  </div>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">


@import "styles/main.scss";


</style>
