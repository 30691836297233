/* eslint-disable */
import { createApp } from 'vue'
import { createRouter,createWebHistory} from 'vue-router'
import App from './App.vue'

import Index from './components/Index.vue';
import CompassDemo from './components/demo/CompassDemo.vue';
import ActiveDemo from './components/demo/ActiveDemo.vue';
import FollowDemo from './components/demo/FollowDemo.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      // { path: '/:pathMatch(.*)*', redirect: '/' },
      {path: '/', component: Index },
      {path: '/compass', component: CompassDemo},
      {path: '/active', component: ActiveDemo},
      {path: '/follow', component: FollowDemo}
   ],
  //  base: process.env.NODE_ENV === 'production' ? '/' : 'https://alcon.iamnwd.com/'
  })

  

createApp(App)
  .use(router)
  .mount('#app');


