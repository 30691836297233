/* eslint-disable */
<template>
    <div id="follow" class="wrapper">
       <p>Follow? {{ follow }} -- {{ curMX }} - {{ curMY }}</p>
       
        <div class="circ" ref="myCirc" 
        @touchstart="follow=true"
        @touchend="follow=false" 
        @mousedown="follow=true" 
        @mouseup="follow= false" :style="{'top':curMY + 'px', 'left':curMX + 'px'}"></div>
    </div>

  </template>
  
  <script>
// import axios from 'axios';
// import Display from './Display.vue';
// import HelperIcons from '@/components/HelperIcons.vue';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// import ActiveDemoTic from './ActiveDemoTic.vue';


export default {
  name: 'FollowDemo',
  components: {
   // ActiveDemoTic
    // Display
    // HelperIcons
    // SvgHelper
    // HelloWorld
  },
  data(){
    return{


      follow:false,  
    curMX:0,
    curMY:0,

      asShow:null,
      tsShow: null,
      timeHold:4, 
      dampen: false,
      transition:false,
      transTime:0,
      dampenTime:1,
      timer: null,
      curSec:0,
      curView: 'view1',
      arrLength: 120,
      arrays: {
        type1: {label:'foo1', arr:[]},
        as: {label:'Intelligent Sentry', 
            arr:[ false, false, true, false, true, false, true, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, true, true, false, false, false, false, false, false, false, false, false, true, false, false, true, false, false, false, false, false, true, false, false, true, false, true, false, true, true, false, false, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, false, true, false, false, true, false, false, true, false, false, true, false, true, false, false, false, false, false, true, false, false, false, false, false, true, true, false, false, false, false, true, false, false, true, false, false, false, false, false, true, true, true, true, false, false, false, false, false, false, false ]
        },
        ts: {label:'Thermal Sentry',
            arr:[ false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, false, false, false, true, true, false, true, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false ]
        }
      }
      
    }
  },
  mounted() {
    /* eslint-disable */
    // this.arr1.length = 100;
    // window.addEventListener('resize', this.bw);
    // this.bw();
    // this.remoteCall();
    window.addEventListener('touchmove', (e) => {
        /* eslint-disable */
        
        if(this.follow)
        {
            console.log(e);
            this.curMX = e.changedTouches[0].clientX - 15;
            this.curMY = e.changedTouches[0].clientY - 15;
            // console.log('f');
            // this.$refs.myCirc.$el.style.top = e.y;
        }
    })

    window.addEventListener('mousemove', (e) => {
        /* eslint-disable */
        
        if(this.follow)
        {
            this.curMX = e.x - 15;
            this.curMY = e.y - 15;
            // console.log('f');
            // this.$refs.myCirc.$el.style.top = e.y;
        }
    })
  },
  unmounted() {
    // window.removeEventListener('resize', this.bw);
    // turn off timer if not null?
    if (this.timer != null){
        clearInterval(this.timer);
    }
  },
  computed:{
   
    asState: function(){
        var state = false;
        // if (this.dampen){
        //         if (this.asShow != null && this.curSec < this.asShow){
        //         state=true;
        //     }
        // } else {
        //     if (this.arrays.as.arr[this.curSec]==1){
        //         state=true;
        //     }
        // }
        if (this.asShow != null && this.curSec < this.asShow){
               state=true;
            }
        return state;
    },
    tsState: function(){
        var state = false;
        // if (this.dampen){
        //         if (this.tsShow != null && this.curSec < this.tsShow){
        //         state=true;
        //     }
        // } else {
        //     if (this.arrays.ts.arr[this.curSec]==1){
        //         state=true;
        //     }
        // }
        if (this.tsShow != null && this.curSec < this.tsShow){
                state=true;
            }
        return state;
    }
    
  },
  methods: {
    toggleTimer: function(){
        if (this.timer != null){
            clearInterval(this.timer);
            this.timer = null;
            this.curSec = 0;
            this.asShow = null;
            this.tsShow = null;
            // this.genArrs();
        } else {
            this.timer = setInterval(this.myTimer, 250);
        }
       
    },
    myTimer: function(){
        console.log('t');
        if(this.curSec===this.arrLength){
            this.curSec=0;
            this.asShow = null;
            this.tsShow = null;
        } 
        this.curSec+=1;

        // AS CHECK 
        if (this.arrays.as.arr[this.curSec]){
            this.asShow = (this.curSec + Number(this.dampenTime));
        }
        // TS CHECK
        if (this.arrays.ts.arr[this.curSec]){
            this.tsShow = (this.curSec + Number(this.dampenTime));
        }
        
    },
    setActive: function(arr, i){
        var bool = this.arrays[arr].arr[i];
        bool = !bool;
        // console.log(bool);
        this.arrays[arr].arr[i] = bool;
    },
     genArrs: function(){
        // console.log('foo');
            // for (var arr in this.arrays){
            //     console.log(arr);
            //     console.log(this.arrays[arr].label);
            //     for (var i = 0; i < this.arrLength; i++) {
                    
            //         this.arrays[arr].arr[i] = false;
            //         if(i==10){
            //             this.arrays[arr].arr[i] = true;
            //         }
            //     }
            // }
     }
  }
}
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    // @import "styles/compass.scss";

p {user-select: none; }

  .circ {
    width:30px;
    height:30px;
    background-color: red;
    position: fixed;
  }



  </style>
  