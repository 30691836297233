/* eslint-disable */
<template>
    <div class="tik" :class="{'tik-on':aval, 'tik-viewed': aid<=cursec}" @click="toggleState()" >
     <!-- {{ aid }} {{ aval }} - {{ cursec }} -->
    </div>
</template>
  

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
    props: [
        'aval',
        'aid',
        'cursec'
    ],
    methods:{
        toggleState: function(){
            this.$emit('toggle-state');
        }
    
    },
};
</script>
